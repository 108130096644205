import { CommonModule } from '@angular/common';
import { MatCardModule } from '@angular/material/card';
import { NgModule } from '@angular/core';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatListModule } from '@angular/material/list';
import { MatButtonModule } from '@angular/material/button';

@NgModule({
  imports: [CommonModule],
  exports: [MatCardModule, MatExpansionModule, MatListModule, MatButtonModule],
  providers: []
})
export class MaterialModule {}
