<div class="header header-fill-size" [class.bg-blue]="isHeaderBlueWithoutLogo">
  <div class="header-content">
    <div class="header-content-logos" *ngIf="!isHeaderBlueWithoutLogo">
      <div class="main-logo">
        <img src="{{ headerIconURL }}" alt="Main logo" class="main-logo-img" />
        <h2 class="main-logo-text">
          {{ headerTitle }}
        </h2>
      </div>
      <img src="assets/logos/city.svg" alt="City logo" class="city-logo" />
    </div>
  </div>
</div>
